<template>
    <div style="background: var(--v-component-base) !important">
        <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="8" lg="6">
                <el-input suffix-icon="el-icon-search" v-model="roleSearch" clearable placeholder="Search">
                    <template slot="prepend">
                        <el-button @click="roleDialog= true, roleItem = {modalType: 'Add'}"><i class="el-icon-plus"></i>
                            Add Role</el-button>
                    </template>
                </el-input>
            </v-col>
        </v-row>
        <v-data-table :items="filterRoles" :loading="loading" :headers="headers" style="cursor: pointer;">
            <template v-slot:[`item.action`]="{ item }">
                <v-btn class="mx-1" small icon @click="editRole(item)">
                    <v-icon color="edit">edit</v-icon>
                </v-btn>
                <v-btn class="mx-1" small icon @click="removeRole(item)">
                    <v-icon color="danger">remove_circle_outline</v-icon>
                </v-btn>
                <v-btn class="mx-1" small icon @click="$router.push({path: '/role/admin/' + item.id})">
                    <v-icon>launch</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!-- Add/Edit Role dialog -->
        <el-dialog v-loading="loading" :title="roleItem.modalType + 'Role'" :visible.sync="roleDialog"
            :fullscreen="$vuetify.breakpoint.mobile" width="850px" :closed="() =>{roleItem = {}, roleDialog = false}">
            <el-form class="modal-form" :model="roleItem" ref="form" @submit.native.prevent="saveRole">
                <el-form-item prop="name">
                    <el-input v-model="roleItem.name" placeholder="Name" clearable>
                        <v-icon v-if="roleItem.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                        <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                    </el-input>
                </el-form-item>
                <el-form-item prop="description">
                    <el-input type="textarea" v-model="roleItem.description" placeholder="Description"
                        prefix-icon="fas fa-user" clearable>
                        <v-icon v-if="roleItem.description" slot="suffix" class="mr-1" small color="green">check
                        </v-icon>
                        <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                    </el-input>
                </el-form-item>

                <el-form-item style="text-align: center">
                    <el-button v-if="roleItem.modalType=='Add'" :loading="loading" style="min-width: 40%" type="primary"
                        :disabled="!roleItem.name" native-type="submit" block> {{!loading?'Submit':''}}</el-button>
                    <el-button v-else :loading="loading" style="min-width: 40%" type="primary"
                        :disabled="!roleItem.name" native-type="submit" block> {{!loading?'Save':''}}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
    
<script>
export default {
    props: ['orgId'],
    data: () => ({
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center',
        },
        {
            text: 'Description',
            value: 'description',
            align: 'center',
        }
        ],
        loading: false,
        roles: [],
        roleDialog: false,
        roleItem: {},
        roleSearch: '',
    }),
    computed: {
        filterRoles() {
            let result = this.roles
            if (this.roleSearch) {
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.roleSearch.toLowerCase())
                )
            }
            return result
        },
    },
    created() {
        this.getRoles()
    },
    methods: {
        editRole(role) {
            this.roleItem = role
            this.roleItem.modalType = 'Edit'
            this.roleDialog = true
        },
        async getRoles() {
            this.loading = true;
            this.roles = await this.$API.getOrganisationRoleAdmin(this.orgId)
            this.loading = false;
        },
        queryRoleSearch(queryString, cb) {
            var links = this.roles;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            // call callback function to return suggestions
            cb(results);
        },
        async removeRole(role) {
            this.$confirm('Are you sure you want to delete this role?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.loading = true;
                await this.$API.updateRoleAdmin({
                    id: role.id,
                    isActive: false,
                    isDeleted: true
                })
                this.loading = false;
                this.roles.splice(this.roles.indexOf(role), 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async saveRole() {
            this.roleItem.organisationId = this.orgId
            if (this.roleItem.id) {
                this.loading = true;
                await this.$API.updateRoleAdmin(this.roleItem)
                this.loading = false;
                this.roleDialog = false
                this.roleItem = {}
                this.getRoles()
            } else {
                this.loading = true;
                await this.$API.createRoleAdmin(this.roleItem)
                this.loading = false;
                this.roleDialog = false
                this.roleItem = {}
                this.getRoles()
                
            }
        },
    }
}
</script>